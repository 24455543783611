import React, { Component } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from '@emotion/styled'
import { Inner } from 'styles/structure';
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Logo from "components/_ui/Logo";
import MobileSearchBox from "components/MobileSearchBox";
import SearchBox from "components/SearchBox";
import CleanInput from 'components/_ui/CleanInput';
import SocialBar from 'components/_header/SocialBar';
import Panel from 'components/_header/Panel';
import Caret from "vectors/caret-down.svg";
import { window, document } from 'browser-monads';

const headerHeightMobile = '6.4rem';
const headerHeightDesktop = '10.4rem';

const HeaderContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  
  & + * {
    margin-top: ${headerHeightMobile};
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: ${headerHeightDesktop};
    }
  }
`;

const HeaderBar = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: ${headerHeightMobile};
  padding: 2.2rem 0 2rem;
  background-color: #fff;
  transition: box-shadow 0.12s ease-in-out;
  z-index: 100;
  
  .is-sticky &,
  .is-open &,
  .has-hex-bg & {
    
    box-shadow: 0 0.4rem 0.4rem rgba(0,0,0,0.06); !important;
  }
  
  .has-open-panel & {
    border-bottom: 1px solid ${colors.grey300};
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    height: ${headerHeightDesktop};
    padding-top: 3.4rem;
    box-shadow: none;
    
    // prevent drop shadow on desktop when: header is not sticky but mobile nav is open; panel is open
    .is-open:not(.is-sticky) &,
    .has-open-panel & {
      box-shadow: none;
    }
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const HeaderLinks = styled.nav`
  display: none;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: center;
  }
`;

const HeaderLink = styled.button`
  position: relative;
  font-size: 1.6rem;
  color: ${colors.grey800};
  // better hit area, removes default padding from buttons
  padding: 0.4rem;
  transition: color 0.08s ease-in-out;
  user-select: none;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -0.2rem;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background-color: ${colors.orange500};
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.12s ease-out;
  }
  
  & + * {
    margin-left: 1.04vw;
  }
  
  &.is-donate {
    color: ${colors.teal600};
    font-weight: 600;
  }
  
  &:not(.is-open):hover {
    color: ${colors.orange500};
    cursor: pointer;
  }
  
  &.is-open {
    &::after {
      transform: scaleX(1);
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    & + * {
      margin-left: 2.77vw;
    }
  }
`;

const HeaderHamburger = styled(CleanInput)`
  display: block;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-72%);
  height: 2.3rem;
  cursor: pointer;

  > span {
    display: block;
    position: relative;
    width: 2.4rem;
    height: 0.2rem;
    background-color: ${colors.teal700};
    transition: all 0.4s ease;
    transform-origin: center center;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.2rem;
      background-color: inherit;
      transition: all 0.4s ease;
    }

    &::before {
      top: -0.6rem;
    }

    &::after {
      top: 0.6rem;
    }
  }
  
  .is-open & {    
    span {
      transform: rotate(135deg);
      
      &::before,
      &::after {
        top: 0;
        transform: rotate(90deg);
      }
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    right: 3.5rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: none;
  }
`;


const HeaderMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: ${headerHeightMobile};
  width: 100%;
  height: calc(100vh - ${headerHeightMobile});
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  user-select: none;
  
  .is-open & {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
  }
  
  a {
    font-size: 1.6rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: none;
  }
`;

const MobileLinks = styled.ul`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const MobileDrawer = styled.div`
  border-bottom: 1px solid ${colors.grey300};
  
  &.is-open {
    border-bottom: 1px solid ${colors.teal600};
    
    svg {
      fill: #fff;
      transform: translateY(-50%) rotate(180deg);
    }
  }
`;

const MobileToggle = styled.div`
  position: relative;
  padding: 2.4rem 0 2rem;
  transition: background 0.08s ease-in-out;
  cursor: pointer;
  
  svg {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    fill: ${colors.orange500};
    display: block;
    width: 2rem;
    height: 1.3rem;
    transform-origin: center center;
    transition: transform 0.08s ease-in-out;
  }
  
  h5 {
    font-weight: 400;
  }
  
  .is-open > & {
    background-color: ${colors.teal700};
    
    h5 {
      color: #fff;
    }
  }
`;

const MobileContent = styled.div`
  display: none;
  background-color: ${colors.teal600};
  padding: 2.4rem 0;
  
  .is-open > & {
    display: block;
  }
  
  li {
    & + * {
      margin-top: 1.6rem;
    }
  }
  
  a {
    color: #fff;
    font-size: 2.1rem;
    font-weight: 600;
  }
`;

const MobileLink = styled(Link)`
  display: block;
  color: initial;
  border-bottom: 1px solid ${colors.grey300};
  padding: 2.4rem 0 2rem;

  h5 {
    font-weight: 400;
  }
`;

const MobileFooter = styled.footer`
  width: 100%;
  height: 8.8rem;
  padding-top: 4rem;
  flex: 0 0 8.8rem;
  border-top: 1px solid ${colors.grey200};
`;

const MobileConnect = styled(Link)`
  font-size: 1.4rem;
  color: ${colors.teal600};
  font-weight: 600;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
`

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasOpenPanel: false,
      isOpen: false,
      isSticky: false,
      content: '',
      drawers: [],
    };
  }

  togglePanel = (section) => {
    if (this.state.content !== section) {
      this.setState({
        hasOpenPanel: true,
        content: section,
      });
    }
  };

  closePanel = () => {
    this.setState({
      hasOpenPanel: false,
      content: '',
    });
  };

  toggleMobile = () => {
    const bodyClasses = document.getElementsByTagName('body')[0].classList;

    if(this.state.isOpen) {
      bodyClasses.remove('is-locked');
    } else {
      bodyClasses.add('is-locked');
    }

    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));

    this.closeDrawers();
  };

  toggleDrawer = drawer => {
    let newDrawers = this.state.drawers;

    if(this.state.drawers.includes(drawer)) {
      newDrawers.splice(newDrawers.indexOf(drawer), 1);
    } else {
      newDrawers.push(drawer);
    }

    this.setState({
      drawers: newDrawers,
    });
  };

  closeDrawers = () => {
    this.setState({
      drawers: [],
    });
  }

  onScroll = () => {
    if (!this.state.isSticky && window.pageYOffset >= 20) {
      this.setState({
        isSticky: true,
      });
    } else if (this.state.isSticky && window.pageYOffset < 20) {
      this.setState({
        isSticky: false,
      });
    }
  };

  // detects clicks outside header content
  handleClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    this.closePanel();
  };

  captureEscape(event) {
    if (event.keyCode === 27) {
      this.closePanel();
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll.bind(this));
    document.addEventListener('mousedown', this.handleClick, false);

    document.onkeydown = this.captureEscape.bind(this);

    this.onScroll();
  }

  render() {
    const self = this;

    const data = this.props.data.allPrismicHeader.edges[0].node.data;

    if (!data) return null;

    return (
      <HeaderContainer
        className={`${self.state.hasOpenPanel ? 'has-open-panel' : ''} ${self.state.isOpen ? 'is-open' : ''} ${self.state.isSticky ? 'is-sticky' : ''}`}
        onMouseLeave={() => {self.closePanel()}}
        ref={node => this.node = node}
      >
        <HeaderBar>
          <Inner>
            <HeaderContent>
              <Logo/>

              <HeaderLinks>
                <HeaderLink
                  as={Link}
                  to={`/programs`}
                  onMouseEnter={() => {self.togglePanel('programs')}}
                  className={self.state.content === 'programs' ? 'is-open' : null}
                >
                  Programs
                </HeaderLink>

                <HeaderLink
                  as={Link}
                  to={`/resources`}
                  onMouseEnter={() => {self.togglePanel('resources')}}
                  className={self.state.content === 'resources' ? 'is-open' : null}
                >
                  Resources
                </HeaderLink>

                <HeaderLink
                  as={Link}
                  to={`/about-us`}
                  onMouseEnter={() => {self.togglePanel('about')}}
                  className={self.state.content === 'about' ? 'is-open' : null}
                >
                  About Us
                </HeaderLink>

                <HeaderLink
                  as={Link}
                  to={`/blog`}
                  onMouseEnter={() => {self.togglePanel('news')}}
                  className={self.state.content === 'news' ? 'is-open' : null}
                >
                  News
                </HeaderLink>

                <HeaderLink
                  as={Link}
                  onMouseEnter={() => {self.closePanel()}}
                  className="is-donate"
                  activeClassName="Link--is-active"
                  to="/donate">
                  Donate
                </HeaderLink>

                <SocialBar />

                <SearchBox />
              </HeaderLinks>

              <HeaderHamburger
                as="button"
                type="button"
                onClick={() => {
                  self.toggleMobile();
                }}
              >
                <span></span>
              </HeaderHamburger>
            </HeaderContent>
          </Inner>
        </HeaderBar>

        <Panel current={self.state.content}/>

        <HeaderMobile>
          <MobileSearchBox />

          <MobileLinks>
            <MobileDrawer className={self.state.drawers.includes('programs') ? 'is-open' : ''}>
              <MobileToggle onClick={() => {self.toggleDrawer('programs')}}>
                <Inner>
                  <h5>Programs</h5>

                  <Caret />
                </Inner>
              </MobileToggle>

              <MobileContent>
                <Inner>
                  <ul>
                    <li>
                      <Link to='/programs'>All Programs</Link>
                    </li>

                    {data.programs && data.programs.map((program, i) => (
                      <li key={i}>
                        <Link to={program.url}>{program.heading}</Link>
                      </li>
                    ))}
                  </ul>
                </Inner>
              </MobileContent>
            </MobileDrawer>

            <MobileDrawer className={self.state.drawers.includes('resources') ? 'is-open' : ''}>
              <MobileToggle onClick={() => {self.toggleDrawer('resources')}}>
                <Inner>
                  <h5>Resources</h5>

                  <Caret />
                </Inner>
              </MobileToggle>

              <MobileContent>
                <Inner>
                  <ul>
                    <li>
                      <Link to='/resources'>All Resources</Link>
                    </li>

                    <li>
                      <Link to='/resources'>Collections</Link>
                    </li>

                    {data.collections && data.collections.map((collection, i) => (
                      <li key={i}>
                        <Link to={collection.url}>{collection.heading}</Link>
                      </li>
                    ))}
                  </ul>
                </Inner>
              </MobileContent>
            </MobileDrawer>

            <MobileDrawer className={self.state.drawers.includes('about') ? 'is-open' : ''}>
              <MobileToggle onClick={() => {self.toggleDrawer('about')}}>
                <Inner>
                  <h5>About Us</h5>

                  <Caret />
                </Inner>
              </MobileToggle>

              <MobileContent>
                <Inner>
                  <ul>
                    <li>
                      <Link to='/about-us'>About Us</Link>
                    </li>

                    {data.about_pages && data.about_pages.map((page, i) => (
                      <li key={i}>
                        <Link to={page.url}>{page.heading}</Link>
                      </li>
                    ))}
                  </ul>
                </Inner>
              </MobileContent>
            </MobileDrawer>

            <MobileDrawer className={self.state.drawers.includes('news') ? 'is-open' : ''}>
              <MobileToggle onClick={() => {self.toggleDrawer('news')}}>
                <Inner>
                  <h5>News</h5>

                  <Caret />
                </Inner>
              </MobileToggle>

              <MobileContent>
                <Inner>
                  <ul>
                    <li>
                      <Link to='/page/media'>In the News</Link>
                    </li>

                    <li>
                      <Link to='/blog'>Blog</Link>
                    </li>
                  </ul>
                </Inner>
              </MobileContent>
            </MobileDrawer>

            <MobileLink to='/donate'><Inner><h5>Donate</h5></Inner></MobileLink>
          </MobileLinks>

          <MobileFooter>
            <Inner>
              <SocialBar />
              <MobileConnect to='/contact-us'>Let's Connect</MobileConnect>
            </Inner>
          </MobileFooter>
        </HeaderMobile>
      </HeaderContainer>
    )
  }
}

const HeaderComponent = (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicHeader {
          edges {
            node {
              data {
                programs_description
                programs {
                  heading
                  description
                  url
                }
                resources_description
                collections {
                  heading
                  description
                  url
                }
                about_us_description
                about_pages {
                  heading
                  description
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);

export default HeaderComponent;